import React from 'react'

import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'

import { CONTENT } from '../../constants/constants'
import { charSet, guid } from '../../utils'

import Form from '../common/Form'
import FormHeader from '../common/FormHeader'
import { useAxiosPrivate } from '../../hooks'
import { request } from '../../api/axios'
import { API_URL } from '../../api/constants'
import InputFieldWithAction from '../InputFieldWithAction'

const getRequiredFieldsData = (fields, dataSource) =>
  fields.reduce((requiredData, currField) => {
    const { name } = currField
    return { ...requiredData, [name]: dataSource[name] || '' }
  }, {})

const generateCode = (length, charSet) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const newGuid = guid(length, charSet)
      resolve(newGuid)
    }, 500)
  })
}

const syncFacilityNameAndTimezoneWithKardiaAdmin = async (teamId, axiosInstance, searchProp = 'name') => {
  const response = await request({
    axiosInstance,
    path: `${API_URL.TEAMS}/${teamId}`,
  })
  if (response.status === 200) {
    return response.data[searchProp]
  }
}

const FacilityDetailsForm = ({
  prefillData,
  loading,
  error,
  onSubmit,
  readOnlyFields,
  viewMode,
  triggerOnMountFields = []
}) => {
  const axiosPrivate = useAxiosPrivate()

  const fields = [
    {
      label: 'K-Pro-Team',
      name: 'kpro_team_id',
      hint: 'Search with K-Pro Team Id or Facility Name',
      required: true,
    },
    {
      label: 'Facility Name',
      name: 'facility_name',
      required: true,
      component: ({ state, ...restProps }) => (
        <InputFieldWithAction
          {...restProps}
          triggerOnMount={triggerOnMountFields.includes(restProps?.name)}
          action={() =>
            syncFacilityNameAndTimezoneWithKardiaAdmin(state?.kpro_team_id, axiosPrivate)
          }
          actionTitle="Sync Facility name with Kardia Admin"
        />
      ),
    },
    {
      label: 'Street',
      name: 'street',
    },
    {
      label: 'Practice timezone',
      name: 'practice_timezone',
      required: true,
      component: ({ state, ...restProps }) => (
        <InputFieldWithAction
          {...restProps}
          triggerOnMount={triggerOnMountFields.includes(restProps?.name)}
          action={() =>
            syncFacilityNameAndTimezoneWithKardiaAdmin(state?.kpro_team_id, axiosPrivate, 'practice_timezone')
          }
          actionTitle="Sync Practice Timezone name with Kardia Admin"
        />
      )
    },
    {
      label: 'City',
      name: 'city',
    },
    {
      label: 'State',
      name: 'state',
    },
    {
      label: 'Country',
      name: 'country',
    },
    {
      label: 'Postal Code',
      name: 'postal_code',
    },
    {
      label: 'Connected Application',
      name: 'connected_application',
    },
    {
      label: 'Facility Code',
      name: 'facility_code',
      component: (params) => (
        <InputFieldWithAction
          {...params}
          triggerOnMount={triggerOnMountFields.includes(params?.name)}
          action={() => generateCode(5, charSet.ALPHA_UPPER)}
        />
      ),
    },
    {
      label: 'X-API-Key',
      name: 'xapi_key',
      charSet: charSet.ALPHANUMERIC,
      length: 32,
      component: (params) => (
        <InputFieldWithAction
          {...params}
          triggerOnMount={triggerOnMountFields.includes(params?.name)}
          action={() => generateCode(32, charSet.ALPHANUMERIC)}
        />
      ),
    },
  ]

  return (
    <Paper
      sx={{
        padding: 3,
      }}
    >
      <FormHeader marginBottom={2} {...CONTENT.facility_details} />
      <Divider />
      <Form
        fields={fields}
        initialData={
          prefillData ? getRequiredFieldsData(fields, prefillData) : null
        }
        readOnlyFields={readOnlyFields}
        columnCount={2}
        onSubmit={onSubmit}
        loading={loading}
        error={error}
        viewMode={viewMode}
      />
    </Paper>
  )
}

export default FacilityDetailsForm
