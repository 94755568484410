import React from 'react'

import { Outlet } from 'react-router-dom'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import Layout from '../common/Layout'

const AppLayout = ({ sideNav, header, footer, aside }) => {
  return (
    <Layout.Container>
      {/* Side navigation drawer */}
      {sideNav && <Layout.Nav>{sideNav}</Layout.Nav>}

      <Layout.Column>
        {/* Header Section*/}
        {header && <Layout.Header>{header}</Layout.Header>}
        <Breadcrumbs />
        {/* Main Content Section */}
        <Layout.Main elevation={0}>
          <Outlet />
        </Layout.Main>

        {/* Footer Section*/}
        {footer && <Layout.Footer>{footer}</Layout.Footer>}
      </Layout.Column>

      {/* Aside Section*/}
      {aside && <Layout.Aside>{aside}</Layout.Aside>}
    </Layout.Container>
  )
}

export default AppLayout
