import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { request } from '../../api/axios'
import { API_URL, METHODS, STATUS } from '../../api/constants'

import Typography from '@mui/material/Typography'

import Loader from '../../components/common/Loader'
import FacilityConfigForm from '../../components/FacilityConfigForm'
import FacilityDetailsForm from '../../components/FacilityDetailsForm'
import Layout from '../../layouts/common/Layout'
import Switch from '../../components/common/Switch'
import Snackbar from '../../components/common/Snackbar'

import { pageActions } from '../slices/page.slice'

import { useAxiosPrivate, useFetchFunction } from '../../hooks'
import { CONTENT, PAGE_TITLE } from '../../constants/constants'

const readOnlyFields = ['kpro_team_id', 'facility_name', 'practice_timezone']

const getRequiredFieldsData = (facilityData) => {
  const {
    facility_name,
    facility_code,
    practice_timezone,
    kpro_team_id,
    city,
    street,
    postal_code,
    country,
    xapi_key,
    connected_application,
    state,
  } = facilityData?.facility || {}
  return {
    facility_name,
    facility_code,
    practice_timezone,
    kpro_team_id,
    city,
    street,
    postal_code,
    country,
    xapi_key,
    connected_application,
    state,
  }
}

export const getChangedValues = (prevState, currState) => {
  let result = {}
  Object.keys(currState).forEach((currKey) => {
    if (prevState[currKey] !== currState[currKey]) {
      result = { ...result, [currKey]: currState[currKey] }
    }
  })
  return result
}

export const deleteConfigRequest = (id, axiosInstance) => {
  return request({
    axiosInstance,
    path: `${API_URL.FACILITY_CONFIG}/${id}`,
    method: METHODS.DELETE,
    data: id,
  })
}
const UpdateFacility = () => {
  const { facilityId } = useParams()
  const { state } = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const [viewMode, setViewMode] = useState(state?.mode || 'VIEW')
  const [facilityUpdateStatus, setFacilityUpdateStatus] = useState(STATUS.IDLE) // idle || loading || completed
  const [configUpdateStatus, setConfigUpdateStatus] = useState(STATUS.IDLE) // idle || loading || completed
  const [showSnackbar, setShowSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  })

  const updatedFacilityData = useRef({})
  const currentFacilityData = useRef({})
  const dispatch = useDispatch()

  const [facilityLoading, facilityError, facilityData, fetchFacilityFunc] =
    useFetchFunction()

  const [, , configData, fetchConfigFunc] = useFetchFunction()

  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === 'VIEW' ? 'EDIT' : 'VIEW'))
  }

  const getFacilityData = (facilityId) => {
    fetchFacilityFunc({
      axiosInstance: axiosPrivate,
      path: `${API_URL.FACILITIES}/${facilityId}`,
    })
  }

  const getFacilityConfigData = (facilityId) => {
    fetchConfigFunc({
      axiosInstance: axiosPrivate,
      path: `${API_URL.FACILITY_CONFIG}/${facilityId}`,
    })
  }

  useEffect(() => {
    if (facilityId) {
      getFacilityData(facilityId)
      getFacilityConfigData(facilityId)
    }
    // eslint-disable-next-line
  }, [facilityId])

  useEffect(() => {
    if (facilityData) {
      const title = facilityData?.facility?.facility_name
      dispatch(pageActions.updateTitle(title))
      currentFacilityData.current = getRequiredFieldsData(facilityData)
    }
    if (facilityError) {
      dispatch(pageActions.updateTitle(PAGE_TITLE.FACILITIES_UPDATE))
    }
  }, [facilityData, facilityError, dispatch])

  const updateFacility = async (payload) => {
    const data = getChangedValues(currentFacilityData.current, payload)
    try {
      setFacilityUpdateStatus(STATUS.LOADING)
      const response = await request({
        axiosInstance: axiosPrivate,
        path: `${API_URL.FACILITIES}/${facilityId}`,
        method: METHODS.PUT,
        data,
      })
      if (response.status === 200) {
        updatedFacilityData.current = response?.data?.facility
        currentFacilityData.current = response?.data?.facility
        setShowSnackbar({
          open: true,
          message: CONTENT.facility_details.success.update,
          severity: 'success',
        })
        setFacilityUpdateStatus(STATUS.COMPLETED)
      }
    } catch (error) {
      console.log(error)
      setShowSnackbar({
        open: true,
        message: CONTENT.UNKNOWN_ERROR,
        severity: 'error',
      })
      setFacilityUpdateStatus(STATUS.FAILED)
    }
  }

  const updateConfig = async (payload) => {
    try {
      setConfigUpdateStatus(STATUS.LOADING)
      const response = await request({
        axiosInstance: axiosPrivate,
        path: `${API_URL.FACILITY_CONFIG}/${facilityId}`,
        method: METHODS.PUT,
        data: payload,
      })
      if (response.status === 200) {
        getFacilityConfigData(facilityId)
        setShowSnackbar({
          open: true,
          message: CONTENT.facility_configs.success.update,
          severity: 'success',
        })
        setConfigUpdateStatus(STATUS.COMPLETED)
      }
    } catch (error) {
      console.log(error)
      setShowSnackbar({
        open: true,
        message: CONTENT.UNKNOWN_ERROR,
        severity: 'error',
      })
      setConfigUpdateStatus(STATUS.FAILED)
    }
  }

  const deleteConfig = async (id) => {
    try {
      const response = await deleteConfigRequest(id, axiosPrivate)
      if (response.status === 200) {
        setShowSnackbar({
          open: true,
          message: response.data?.message,
          severity: 'success',
        })
      }
    } catch (error) {
      setShowSnackbar({
        open: true,
        message: CONTENT.UNKNOWN_ERROR,
        severity: 'error',
      })
    }
  }
  if (facilityLoading) return <Loader />
  if (facilityError)
    return (
      <Layout.CenterWrapper>
        <Typography variant="h2" maxWidth="md">
          {facilityError?.errorMsg}
        </Typography>
      </Layout.CenterWrapper>
    )
  if (facilityData) {
    const requiredFacilityFieldsData = getRequiredFieldsData(facilityData)
    const initialFacilityData = {
      ...requiredFacilityFieldsData,
      ...(facilityUpdateStatus === STATUS.COMPLETED &&
        updatedFacilityData.current),
    }
    return (
      <>
        <Switch
          checked={viewMode === 'EDIT'}
          toggle={toggleViewMode}
          onText="Edit"
          offText="View"
          sx={{
            marginBottom: 2,
            position: 'sticky',
            top: {
              xs: `calc(24px + 1.5rem)`,
              sm: '24px'
            },
            zIndex: '9',
          }}
        />
        <FacilityDetailsForm
          onSubmit={updateFacility}
          prefillData={initialFacilityData}
          readOnlyFields={readOnlyFields}
          loading={facilityUpdateStatus === STATUS.LOADING}
          viewMode={viewMode}

        />
        <FacilityConfigForm
          prefillData={configData}
          loading={configUpdateStatus === STATUS.LOADING}
          facilityId={facilityId}
          onSubmit={updateConfig}
          viewMode={viewMode}
          status={configUpdateStatus}
          onDelete={deleteConfig}
        />
        <Snackbar
          open={showSnackbar.open}
          children={showSnackbar.message}
          severity={showSnackbar.severity}
          onClose={() => setShowSnackbar((prev) => ({ ...prev, open: false }))}
        />
      </>
    )
  }
}

export default UpdateFacility
