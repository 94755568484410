import { Refresh } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { generateAnimatedIcon } from '../../layouts/common/Loaders'
import InputField from '../common/InputField/InputField'

export const InputFieldWithAction = ({
  action,
  value,
  name,
  rowId,
  onChange,
  label,
  actionTitle = '',
  triggerOnMount = false,
  ...restProps
}) => {
  const [updatedValue, setValue] = useState(value)
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    try {
      setLoading(true)
      const response = await action()
      if (response) {
        setValue(response)
        onChange({
          target: {
            name,
            value: response,
          },
          rowId,
        })
      } else {
        throw new Error('no response')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (value) {
      setValue(value)
    }
  }, [value])

  useEffect(() => {
      onChange(
        {
          target: {
            name,
            value: updatedValue,
          },
        },
        rowId
      )
    // setting the default value on mount 
    if (!updatedValue && triggerOnMount) {
      handleClick()
    }  
    // eslint-disable-next-line
  }, [])

  
  const RefreshIcon = generateAnimatedIcon(Refresh)
  return (
    <InputField
      {...restProps}
      value={loading ? '...' : value || updatedValue}
      name={name}
      label={label}
      rowId={rowId}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClick} title={actionTitle}>
              <RefreshIcon animate={loading} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default InputFieldWithAction
