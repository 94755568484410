import React, { useEffect, useReducer } from 'react'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Layout from '../../../layouts/common/Layout'
import { Delete } from '@mui/icons-material'
import LiveSearch from '../LiveSearch/LiveSearch'
import { ACTIONS, API_URL } from '../../../api/constants'
import InputField from '../InputField/InputField'
import LinkButton from '../LinkButton'
import ROUTES from '../../../route/routes'
import LoadingButton from '../LoadingButton'
import { useRef } from 'react'

const getInitalValueState = (initialArgs, fields) => {
  const initalValueState = fields.reduce(
    (valueState, currentField) => ({
      ...valueState,
      ...([currentField?.name] && { [currentField.name]: '' }),
    }),
    {}
  )
  return { ...initialArgs, ...initalValueState }
}

const getInitalValueStates = (initialArgs, rows, multi) => {
  if (multi) {
    return rows.reduce((valueStates, row) => {
      const { id, fields } = row
      return {
        ...valueStates,
        [id]: getInitalValueState(initialArgs, fields),
      }
    }, {})
  }
  const data = getInitalValueState(initialArgs, rows)
  return data
}

const getInitialErrorState = (initialArgs, fields) => {
  const initalErrorState = fields.reduce((errorState, currentField) => {
    const { name, validator, required } = currentField
    return { ...errorState, [name]: validator || required ? false : true }
  }, {})
  return { ...initialArgs, ...initalErrorState }
}

const getInitalErrorStates = (initialArgs, rows, multi) => {
  if (multi) {
    return rows.reduce((errorStates, row) => {
      const { id, fields } = row
      return {
        ...errorStates,
        [id]: getInitialErrorState(initialArgs, fields),
      }
    }, {})
  }
  return getInitialErrorState(initialArgs, rows)
}

const valueReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTIONS.INIT:
      return payload
    case ACTIONS.UPDATE:
      const { name, value, rowId } = payload
      if (rowId) {
        return {
          ...state,
          [rowId]: {
            ...(state && state[rowId] ? state[rowId] : {}),
            [name]: value,
          },
        }
      }
      return { ...state, [name]: value }

    default:
      return state
  }
}

const errorReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTIONS.INIT:
      return payload
    case ACTIONS.UPDATE:
      const { name, error, rowId } = payload
      if (rowId) {
        return {
          ...state,
          [rowId]: {
            ...state[rowId],
            [name]: error,
          },
        }
      }
      return { ...state, [name]: error }
    default:
      return state
  }
}

const renderFields = (fields, config, id) =>
  fields.map((field) => {
    const {
      valueState,
      handleChange,
      errorState,
      handleError,
      handleKproTeamSelect,
      readOnlyFields = [],
      isViewMode,
    } = config
    const { name, component } = field
    const value = id ? valueState[id] && valueState[id][name] : valueState[name]
    const error = id ? errorState[id] && errorState[id][name] : errorState[name]
    const encryptValue = id
      ? valueState[id] && valueState[id]['encrypted']
      : valueState['encrypted']

    const FieldComponent = component || InputField
    if (name === 'kpro_team_id') {
      const facilityName = id
        ? valueState[id] && valueState[id]['facility_name']
        : valueState['facility_name']

      return (
        <LiveSearch
          key={id ? `${id}-${name}` : name}
          {...field}
          onSelect={handleKproTeamSelect}
          targetName="facility_name"
          value={value}
          targetValue={facilityName}
          onChange={handleChange}
          error={error}
          onError={handleError}
          rowId={id}
          url={API_URL.TEAMS}
          optionLabelProp={{
            primary: 'kpro_id',
            secondary: 'name',
          }}
          searchQueryProp="name"
          idProp="kpro_id"
          dataProperty="listOfTeams"
          noOptionsText="No Teams Available"
          readOnly={readOnlyFields.includes(name) || isViewMode}
        />
      )
    }
    return (
      <FieldComponent
        key={id ? `${id}-${name}` : name}
        {...field}
        encryptValue={encryptValue}
        state={valueState}
        value={value}
        onChange={handleChange}
        error={error}
        onError={handleError}
        rowId={id}
        readOnly={
          (encryptValue && encryptValue === true) ||
          readOnlyFields.includes(name) ||
          isViewMode
        }
      />
    )
  })

const renderRows = (rows, config) =>
  rows.map((row) => {
    const { id, fields } = row
    const { handleRowDelete } = config
    const fieldElements = renderFields(fields, config, id)
    return (
      <Layout.ColumnContainer
        columnCount={5}
        lastColumnWidth="56px"
        key={id}
        marginBottom={3}
      >
        {fieldElements}
        <IconButton
          title="Delete Config"
          sx={{
            marginLeft: 'auto',
            fontSize: '32px',
          }}
          onClick={() => {
            handleRowDelete(id)
          }}
          key={'actions'}
        >
          <Delete fontSize="32px" />
        </IconButton>
      </Layout.ColumnContainer>
    )
  })

const isTruthyObject = (object) =>
  Object.values(object).every((value) => value === true)

const getFormValideState = (errorState, isNested) => {
  if (Object.keys(errorState).length === 0) return false
  if (isNested) {
    return Object.keys(errorState).every((key) =>
      isTruthyObject(errorState[key])
    )
  }
  return isTruthyObject(errorState)
}

const Form = ({
  fields,
  children,
  initialData,
  columnCount,
  onDelete,
  onSubmit,
  loading,
  readOnlyFields,
  viewMode,
}) => {
  const isViewMode = viewMode === 'VIEW'

  const multi =
    Array.isArray(fields) &&
    fields.length > 0 &&
    Array.isArray(fields[0].fields)
  const [valueState, setValueState] = useReducer(
    valueReducer,
    {},
    (initialArgs) => getInitalValueStates(initialArgs, fields, multi)
  )
  const [errorState, setErrorState] = useReducer(
    errorReducer,
    {},
    (initialArgs) => getInitalErrorStates(initialArgs, fields, multi)
  )
  const changedData = useRef({})

  const handleChangedData = (payload) => {
    changedData.current = valueReducer(changedData.current, {
      type: ACTIONS.UPDATE,
      payload
    })
  }
  useEffect(() => {
    if (initialData) {
      setValueState({
        type: 'INIT',
        payload: initialData,
      })
    }
  }, [initialData])

  const handleKproTeamSelect = (value, targetValue, practice_timezone, rowId) => {
    const kproPayload = {
      name: 'kpro_team_id',
      value: value,
      rowId
    }

    setValueState({
      type: ACTIONS.UPDATE,
      payload: kproPayload,
    })

    handleChangedData(kproPayload)

    const facilityNamePayload = {
      name: 'facility_name',
      value: targetValue,
      rowId
    }
    setValueState({
      type: ACTIONS.UPDATE,
      payload: facilityNamePayload,
    })
    handleChangedData(facilityNamePayload)

    const practiceTimezonePayload = {
      name: 'practice_timezone',
      value: practice_timezone,
      rowId
    }
    setValueState({
      type: ACTIONS.UPDATE,
      payload: practiceTimezonePayload,
    })

    handleChangedData(practiceTimezonePayload)
  }

  const handleChange = (e, rowId) => {
    const { name, value } = e.target
    setValueState({
      type: ACTIONS.UPDATE,
      payload: { name, value, rowId },
    })
    handleChangedData({ name, value, rowId })
  }

  const handleError = (name, error, rowId) => {
    setErrorState({
      type: ACTIONS.UPDATE,
      payload: { name, error, rowId },
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(valueState, changedData.current)
    changedData.current = null
  }

  const handleRowDelete = (id) => {
    onDelete(id)
    const valueStateCopy = { ...valueState }
    delete valueStateCopy[id]
    setValueState({
      type: ACTIONS.INIT,
      payload: valueStateCopy,
    })

    const errorStateCopy = { ...errorState }
    delete errorStateCopy[id]
    setErrorState({
      type: ACTIONS.INIT,
      payload: errorStateCopy,
    })
  }
  const isFormValid = getFormValideState(errorState, multi)
  const config = {
    valueState,
    errorState,
    handleChange,
    handleError,
    handleKproTeamSelect,
    handleRowDelete,
    readOnlyFields,
    isViewMode,
  }
  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      style={{
        marginTop: '24px',
      }}
    >
      <Layout.ColumnContainer
        columnCount={columnCount}
        animate
        display={multi ? 'block' : 'flex'}
      >
        {multi ? renderRows(fields, config) : renderFields(fields, config)}
      </Layout.ColumnContainer>
      {children}
      <Stack direction="row" gap={3} marginTop={3}>
        <LinkButton variant="outlined" to={ROUTES.ALL.FACILITIES}>
          Cancel
        </LinkButton>

        <LoadingButton
          type="submit"
          variant="contained"
          disabled={!isFormValid || isViewMode}
          loading={loading}
        >
          Save
        </LoadingButton>
      </Stack>
    </form>
  )
}

export default Form
