import { Box, Typography } from '@mui/material'
import React from 'react'

const FormHeader = ({ title, subTitle, children, sx, ...restProps }) => {
  return (
    <Box sx={{
      ...sx,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 1
    }} {...restProps}>
      <Box>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="caption">{subTitle}</Typography>
      </Box>
      {children}
    </Box>
  )
}

export default FormHeader
